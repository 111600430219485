import { setScene } from '../../../store/performace';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import './style.scss';
import { useEffect, useState } from 'react';

export default function Button() {
    const perform = useSelector(state => state.perform);
    const colors = useSelector(state => state.colors);
    const dispatch = useDispatch();

    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    
    useEffect(() => {
        if (colors.mainColor === 1) {
            document.body.getElementsByClassName('shareBtn')[0].style.setProperty('border', (perform.scene !== 4) ? '1px solid #FFFFFF': (width < 780) ? '1px solid #101527': '1px solid #FFFFFF');
            document.body.getElementsByClassName('shareBtn')[0].getElementsByTagName("P")[0].style.setProperty('color', (perform.scene !== 4) ? '#FFFFFF' : (width < 780) ? '#101527': '#FFFFFF');
        }
        if (colors.mainColor === 2) {
            document.body.getElementsByClassName('shareBtn')[0].style.setProperty('border', '1px solid #101527');
            document.body.getElementsByClassName('shareBtn')[0].getElementsByTagName("P")[0].style.setProperty('color', '#101527');
        }
        if (colors.mainColor === 3) {
            document.body.getElementsByClassName('shareBtn')[0].style.setProperty('border', (perform.scene !== 4) ? '1px solid #1434CB' : (width < 780) ? '1px solid #101527': '1px solid #1434CB');
            document.body.getElementsByClassName('shareBtn')[0].getElementsByTagName("P")[0].style.setProperty('color', (perform.scene !== 4) ? '#1434CB' : (width < 780) ? '#101527': '#1434CB');
        }
        return () => {
            
        }
    });

    const handleClick = () => {
        dispatch(setScene(5))
    }
    
    return (
        <div 
            onClick={handleClick}
            className="shareBtn">
            {(width > 780) ?
            
                (colors.mainColor === 1) ? 
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.875 11.5C5.60089 11.5 7 10.1009 7 8.375C7 6.64911 5.60089 5.25 3.875 5.25C2.14911 5.25 0.75 6.64911 0.75 8.375C0.75 10.1009 2.14911 11.5 3.875 11.5Z" stroke="white" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.125 7.125C16.8509 7.125 18.25 5.72589 18.25 4C18.25 2.27411 16.8509 0.875 15.125 0.875C13.3991 0.875 12 2.27411 12 4C12 5.72589 13.3991 7.125 15.125 7.125Z" stroke="white" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.125 17.125C16.8509 17.125 18.25 15.7259 18.25 14C18.25 12.2741 16.8509 10.875 15.125 10.875C13.3991 10.875 12 12.2741 12 14C12 15.7259 13.3991 17.125 15.125 17.125Z" stroke="white" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.78833 7.24247L12.2117 5.1333" stroke="white" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.6709 9.77319L12.3292 12.6024" stroke="white" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                : (colors.mainColor === 2) ? 
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.875 11.5C5.60089 11.5 7 10.1009 7 8.375C7 6.64911 5.60089 5.25 3.875 5.25C2.14911 5.25 0.75 6.64911 0.75 8.375C0.75 10.1009 2.14911 11.5 3.875 11.5Z" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.125 7.125C16.8509 7.125 18.25 5.72589 18.25 4C18.25 2.27411 16.8509 0.875 15.125 0.875C13.3991 0.875 12 2.27411 12 4C12 5.72589 13.3991 7.125 15.125 7.125Z" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.125 17.125C16.8509 17.125 18.25 15.7259 18.25 14C18.25 12.2741 16.8509 10.875 15.125 10.875C13.3991 10.875 12 12.2741 12 14C12 15.7259 13.3991 17.125 15.125 17.125Z" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.78827 7.24247L12.2116 5.1333" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.67078 9.77319L12.3291 12.6024" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                :
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.875 11.5C5.60089 11.5 7 10.1009 7 8.375C7 6.64911 5.60089 5.25 3.875 5.25C2.14911 5.25 0.75 6.64911 0.75 8.375C0.75 10.1009 2.14911 11.5 3.875 11.5Z" stroke="#1434CB" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.125 7.125C16.8509 7.125 18.25 5.72589 18.25 4C18.25 2.27411 16.8509 0.875 15.125 0.875C13.3991 0.875 12 2.27411 12 4C12 5.72589 13.3991 7.125 15.125 7.125Z" stroke="#1434CB" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.125 17.125C16.8509 17.125 18.25 15.7259 18.25 14C18.25 12.2741 16.8509 10.875 15.125 10.875C13.3991 10.875 12 12.2741 12 14C12 15.7259 13.3991 17.125 15.125 17.125Z" stroke="#1434CB" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.78809 7.24247L12.2114 5.1333" stroke="#1434CB" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.6709 9.77319L12.3292 12.6024" stroke="#1434CB" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            
            :
            
                (colors.mainColor === 1) ? 
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.875 11.5C5.60089 11.5 7 10.1009 7 8.375C7 6.64911 5.60089 5.25 3.875 5.25C2.14911 5.25 0.75 6.64911 0.75 8.375C0.75 10.1009 2.14911 11.5 3.875 11.5Z" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.125 7.125C16.8509 7.125 18.25 5.72589 18.25 4C18.25 2.27411 16.8509 0.875 15.125 0.875C13.3991 0.875 12 2.27411 12 4C12 5.72589 13.3991 7.125 15.125 7.125Z" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.125 17.125C16.8509 17.125 18.25 15.7259 18.25 14C18.25 12.2741 16.8509 10.875 15.125 10.875C13.3991 10.875 12 12.2741 12 14C12 15.7259 13.3991 17.125 15.125 17.125Z" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.78827 7.24247L12.2116 5.1333" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.67078 9.77319L12.3291 12.6024" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                : (colors.mainColor === 2) ? 
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.875 11.5C5.60089 11.5 7 10.1009 7 8.375C7 6.64911 5.60089 5.25 3.875 5.25C2.14911 5.25 0.75 6.64911 0.75 8.375C0.75 10.1009 2.14911 11.5 3.875 11.5Z" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.125 7.125C16.8509 7.125 18.25 5.72589 18.25 4C18.25 2.27411 16.8509 0.875 15.125 0.875C13.3991 0.875 12 2.27411 12 4C12 5.72589 13.3991 7.125 15.125 7.125Z" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.125 17.125C16.8509 17.125 18.25 15.7259 18.25 14C18.25 12.2741 16.8509 10.875 15.125 10.875C13.3991 10.875 12 12.2741 12 14C12 15.7259 13.3991 17.125 15.125 17.125Z" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.78827 7.24247L12.2116 5.1333" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.67078 9.77319L12.3291 12.6024" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                :
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.875 11.5C5.60089 11.5 7 10.1009 7 8.375C7 6.64911 5.60089 5.25 3.875 5.25C2.14911 5.25 0.75 6.64911 0.75 8.375C0.75 10.1009 2.14911 11.5 3.875 11.5Z" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.125 7.125C16.8509 7.125 18.25 5.72589 18.25 4C18.25 2.27411 16.8509 0.875 15.125 0.875C13.3991 0.875 12 2.27411 12 4C12 5.72589 13.3991 7.125 15.125 7.125Z" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.125 17.125C16.8509 17.125 18.25 15.7259 18.25 14C18.25 12.2741 16.8509 10.875 15.125 10.875C13.3991 10.875 12 12.2741 12 14C12 15.7259 13.3991 17.125 15.125 17.125Z" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.78827 7.24247L12.2116 5.1333" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.67078 9.77319L12.3291 12.6024" stroke="#101527" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
            <p>
                {/* Share */}
                Поділитися
            </p>
        </div>
    );
}