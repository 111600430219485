import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './style.scss';

export default function Footer() {
    const perform = useSelector(state => state.perform);
    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        <footer style={{position: (perform.scene === 4)?(width < 780)?'relative':'absolute':'absolute', marginTop: (perform.scene === 4)?(width < 780)?'25px':'0px':'0px', bottom: (perform.scene === 5) ? '20px':(perform.scene === 4)? (width < 780) ? '0px' : '55px' : '55px'}}>
            {/* <svg width="95" height="20" viewBox="0 0 95 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_81_533)">
                    <path d="M67.6604 19.8855C66.5017 19.8855 65.388 19.6814 64.3496 19.2731C61.8365 18.2995 60.4219 16.211 60.3767 13.4002C60.3165 11.2646 60.3316 9.08187 60.3316 6.97767C60.3316 5.40737 60.3466 3.77426 60.3165 2.17255C60.3015 1.62295 60.3165 0.50796 60.3165 0.50796C61.6408 0.52469 62.9651 0.492509 64.2895 0.508036C64.2895 0.508036 64.3196 1.65435 64.2895 2.23537C64.2293 3.9941 64.2443 5.79995 64.2443 7.52727C64.2443 8.83062 64.2594 10.1968 64.2293 11.5315C64.2293 12.3638 64.3196 13.1804 64.5001 13.9341C64.6506 14.6721 65.087 15.5829 66.2458 15.8185C66.7876 15.9441 67.2842 16.0697 67.7808 16.0697C67.8561 16.0697 67.9313 16.0697 68.0066 16.054H68.0216C70.1887 15.9598 71.1217 14.9862 71.1217 12.8035V2.78497C71.1217 1.97453 71.1217 0.413818 71.1217 0.413818C72.436 0.413818 73.7503 0.413818 75.0645 0.413818C75.0645 0.413818 75.0707 1.94388 75.0645 2.07834C75.0645 2.12544 75.0645 2.15685 75.0645 2.20396V7.87274C75.0645 8.50086 75.0645 9.11328 75.0796 9.7414C75.0946 10.9976 75.0946 12.2853 75.0645 13.5415C75.0194 16.5408 73.2135 18.8334 70.3542 19.5244C69.4362 19.7599 68.5333 19.8855 67.6604 19.8855Z" fill="#101527"/>
                    <path d="M89.0937 4.84535C88.1679 6.6714 87.2404 8.50073 86.1737 10.155C85.2368 8.70139 84.4327 7.07767 83.6299 5.45671C82.8915 3.96587 82.1543 2.47737 81.3161 1.12569C80.955 0.528956 80.6378 0.0163574 80.6378 0.0163574C80.6378 0.0163574 79.104 0.309097 78.803 0.356264C78.7248 0.371099 78.4089 0.437893 78.0969 0.504307C77.7477 0.578709 77.4035 0.652579 77.4035 0.652579L77.4034 2.30342C77.4034 7.20278 77.4034 12.5994 77.3582 17.6244V17.9699C77.3432 18.441 77.3583 19.4973 77.3583 19.4973H81.0904V17.5616C81.0603 15.6772 81.0603 13.7458 81.0603 11.8928V8.64225C81.6598 9.99298 82.3827 11.2665 83.0951 12.5211L83.0958 12.5225L83.3176 12.9135C83.4672 13.1788 83.6205 13.448 83.7738 13.7173C83.929 13.9898 84.0842 14.2623 84.2356 14.5309C84.3732 14.7783 84.6168 15.2158 84.886 15.6989H87.462C87.7313 15.2158 87.9751 14.7783 88.1127 14.5309C88.1886 14.3964 88.2653 14.2609 88.3426 14.1249L88.5747 13.7173L88.5976 13.6773L88.6014 13.6706C88.7458 13.4169 88.8901 13.1634 89.0311 12.9135L89.2548 12.5195C89.967 11.2654 90.69 9.99244 91.2896 8.64225V11.8928C91.2896 13.7458 91.2896 15.6772 91.2594 17.5616V19.4973H94.9934C94.9934 19.4973 95.0085 18.441 94.9934 17.9699V17.6244C94.9482 12.5994 94.9482 7.20278 94.9482 2.30342L94.9481 0.652579C94.9481 0.652579 93.7137 0.387683 93.548 0.356264C93.2469 0.309097 91.7123 0.0163574 91.7123 0.0163574C90.7723 1.53451 89.9337 3.18858 89.0937 4.84535Z" fill="#101527"/>
                    <path d="M59.0093 4.36785V0.523438H45.3103V4.36785H50.0981V19.4926H54.2216V4.36785H59.0093Z" fill="#101527"/>
                    <rect x="39.6392" y="0.523438" width="4.12343" height="18.9692" fill="#101527"/>
                    <path d="M30.2487 19.8855C30.1283 19.8855 30.0229 19.8855 29.9025 19.8855C28.7438 19.8383 27.2539 19.697 25.8544 18.8962C23.7475 17.687 22.7242 15.6928 22.6038 12.6621C22.5737 12.0811 22.5887 11.4844 22.5887 10.8405C22.5887 10.5108 22.6038 10.1496 22.6038 9.77273V9.75703C22.6188 9.41156 22.6188 9.03469 22.6188 8.64212C22.6188 7.73134 22.6188 6.71065 22.7994 5.70566C23.3412 3.03615 24.7407 1.38733 27.0884 0.633589C28.2923 0.256717 29.3909 0.0839844 30.4443 0.0839844C32.1749 0.0839844 33.74 0.570777 35.1998 1.57577L35.2148 1.59147C35.7566 1.99975 36.2382 2.51795 36.6595 3.17747C37.2314 4.11965 37.5817 5.19072 37.7731 6.19261C36.4651 6.48598 35.1838 6.87685 33.8755 7.16976C33.802 6.82057 33.74 6.72635 33.4541 5.84698C33.0026 4.48082 31.4225 4.00973 30.0982 4.00973C29.1501 4.00973 28.2321 4.24528 27.6452 4.65356C26.9228 5.14035 26.5918 5.9255 26.5466 7.26025C26.5015 9.12891 26.5015 11.029 26.5466 12.8976C26.5918 14.625 27.3442 15.5043 29.1501 15.9283C29.5263 16.0068 29.8875 16.0539 30.2637 16.0539C31.0162 16.0539 31.7686 15.8812 32.5512 15.52C33.6347 15.0489 33.8303 13.8241 33.8905 12.8191C33.8905 12.7563 33.8905 12.7249 33.8755 12.7092C33.8002 12.6778 33.6196 12.6778 33.4992 12.6778C32.0746 12.6778 30.65 12.6778 29.2253 12.6778C29.1952 12.2538 29.2253 8.83055 29.2253 8.83055C32.0021 8.83055 34.7788 8.81485 37.5555 8.81485C37.586 9.0299 37.7581 10.2281 37.7731 10.4166C37.9236 12.5522 38.0741 14.7663 36.8552 16.7763C35.1245 19.5557 32.2652 19.8855 30.2487 19.8855Z" fill="#101527"/>
                    <rect x="16.5088" y="0.523438" width="4.12343" height="18.9692" fill="#101527"/>
                    <path d="M5.46278 19.6026C4.96616 19.6026 4.46955 19.6026 3.98798 19.5869C2.65871 19.5869 1.32935 19.5869 0 19.5869V2.14085C0 2.07803 0 2.03092 0 1.96811C0 1.65405 0 0.645223 0 0.645223C0 0.645223 0.692253 0.617655 1.00828 0.617655C1.74568 0.617655 2.49813 0.586249 3.22048 0.570546C3.95788 0.53914 4.72538 0.523438 5.47783 0.523438C6.81719 0.523438 7.90071 0.601952 8.909 0.774685C12.3703 1.40281 14.146 3.50701 14.5072 7.43275C14.6427 9.30141 14.6126 11.2957 14.4169 13.3842C14.0557 17.043 11.5877 19.2885 7.62983 19.5398C6.99778 19.5869 6.30552 19.6026 5.46278 19.6026ZM3.89769 4.51353C3.8827 8.22417 3.88264 11.9348 3.88264 15.6454C4.26904 15.6454 4.65486 15.6297 5.04141 15.6297C5.28219 15.6297 5.53802 15.6297 5.79386 15.6297C6.03464 15.6297 6.29047 15.6297 6.53126 15.6297C7.05797 15.6297 7.46429 15.614 7.84052 15.5826C9.28522 15.457 10.128 14.6247 10.3838 13.0387C10.6848 11.2957 10.5945 9.55266 10.4741 8.07658V8.06087C10.3687 5.42277 9.40561 4.512 6.68175 4.41778C6.45601 4.41778 6.21523 4.41778 5.97444 4.43348C5.71861 4.43348 5.46278 4.44919 5.222 4.44919C4.80662 4.44919 3.89769 4.51353 3.89769 4.51353Z" fill="#101527"/>
                </g>
                <defs>
                    <clipPath id="clip0_81_533">
                        <rect width="95" height="19.8693" fill="white" transform="translate(0 0.0161133)"/>
                    </clipPath>
                </defs>
            </svg>
            <p>
                Experts in hyper personalisation
            </p> */}
            <svg width="158" height="24" viewBox="0 0 158 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.98783 0H0V2.99474H2.98783V0Z" fill="#0F0F0F"/>
<path d="M2.98783 5.83064H0V8.82539H2.98783V5.83064Z" fill="#0F0F0F"/>
<path d="M2.98783 11.6617H0V14.6564H2.98783V11.6617Z" fill="#0F0F0F"/>
<path d="M13.933 0H10.9451V2.99474H13.933V0Z" fill="#0F0F0F"/>
<path d="M19.5998 0H16.6119V2.99474H19.5998V0Z" fill="#0F0F0F"/>
<path d="M143.574 22.3731H138.41V16.2832H136.787V24H145.197V16.2832H143.574V22.3731Z" fill="#0F0F0F"/>
<path d="M99.1608 16.2832V24H107.571V19.3282V19.3135L102.465 19.3282V20.955H105.948V22.3732H100.784V17.91H107.571V16.2832H100.784H99.1608Z" fill="#0F0F0F"/>
<path d="M124.154 16.2832V24H132.564V16.2832H124.154ZM130.941 22.3731H125.777V17.91H130.941V22.3731Z" fill="#0F0F0F"/>
<path d="M149.223 16.2832V24H150.846V20.6165H157.632V16.2832H149.223ZM156.009 18.9897H150.846V17.91H156.009V18.9897Z" fill="#0F0F0F"/>
<path d="M119.794 20.6165V16.2832H111.801V24H113.424V20.6165H114.836L118.171 24H120.477L117.101 20.6165H119.794ZM113.424 17.91H118.172V18.9897H113.424V17.91Z" fill="#0F0F0F"/>
<path d="M47.5298 0V2.99474H61.8147V5.83105H47.5298V14.4518V14.4547H64.8026V14.4518V0H47.5298ZM61.8147 11.4571H50.5177V8.82538H61.8147V11.4571Z" fill="#0F0F0F"/>
<path d="M25.144 0V14.4547H28.1315V8.82538H42.4164V5.83064H28.1315V2.99474H42.4164V0H25.144Z" fill="#0F0F0F"/>
<path d="M69.3989 0V14.4514V14.4547L86.6716 14.4514V11.4571H72.3867V2.99474H86.6716V0H69.3989Z" fill="#0F0F0F"/>
<path d="M92.0187 0V2.99474H99.1607V14.4547H102.149V2.99474H109.291V0H92.0187Z" fill="#0F0F0F"/>
<path d="M137.598 0V14.4547H140.586V2.99474H146.235V11.3162L149.223 9.46606V2.99474H154.871V14.4547H157.859V0H137.598Z" fill="#0F0F0F"/>
<path d="M129.785 11.4571H117.374V0H114.387V14.4514V14.4547H117.374H132.772V0H129.785V11.4571Z" fill="#0F0F0F"/>
<path d="M8.24479 0H5.25696V2.99474H8.24479V0Z" fill="#0F0F0F"/>
<path d="M13.933 5.83064H10.9451V8.82539H13.933V5.83064Z" fill="#0F0F0F"/>
<path d="M19.5998 5.83064H16.6119V8.82539H19.5998V5.83064Z" fill="#0F0F0F"/>
<path d="M8.24479 5.83064H5.25696V8.82539H8.24479V5.83064Z" fill="#0F0F0F"/>
</svg>

        </footer>
    );
}