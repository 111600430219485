import Snowfall from 'react-snowfall';
import './style.scss';

import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export default function Background() {
    const perform = useSelector(state => state.perform);
    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    return (
        <>
            <Snowfall
                color='#FFFEF8'
                speed={[0.5, 1]}
                wind={[-0.5, 0.5]}
            />
            <div className='hill' style={{backgroundPosition: (perform.screen === 2) ? (width < 780)? "center 50vh" : "calc(50% + 200px) 50vh" : "center 50vh" }}>
                
            </div>
        </>
    );
}