import { useSelector } from "react-redux";
import Background from "../../background";
import { Back } from "../../buttons";
import { Scene1 } from "../../scenes";
import { Scene2 } from "../../scenes";

export default function Screen() {
    const perform = useSelector(state => state.perform);

    return (<>
        {(perform.scene != 1) ? <Back/> : null}
        <Background/>
        {(perform.scene == 1) ? <Scene1/> : <Scene2/>}
    </>);    
}