import './style.scss';
import { setScreen, setScene } from '../../../store/performace';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export default function Button() {
    const colors = useSelector(state => state.colors);
    const perform = useSelector(state => state.perform);
    const dispatch = useDispatch();

    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        if (perform.scene === 5 && colors.mainColor === 1) {
            document.body.getElementsByClassName('backBtn')[0].style.setProperty('filter', (width > 780) ? 'brightness(100)': 'none');
        }
    });
    
    const handleClick = () => {
        dispatch(setScene(perform.scene - 1))
    }

    return (
        <p className='backBtn' onClick={handleClick}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.3999 12.0001L8.7999 5.6001M2.3999 12.0001L8.7999 18.4001M2.3999 12.0001H22.3999" stroke="#101527"/>
            </svg>
            {/* Back */}
            Назад
        </p>
    );
}