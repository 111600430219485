import { useSelector } from 'react-redux';

import {default as Santa1} from './santa1';
import {default as Santa2} from './santa2';
import {default as Santa3} from './santa3';

export default function Santa() {

    const colors = useSelector(state => state.colors);

    return(
            (colors.mainColor === 1)? 
            <Santa1/>
            : (colors.mainColor === 2)? 
            <Santa2/>
            :
            <Santa3/>
    );
}











































