import { HOST2 } from '../../const';
import './style.scss';

export default function Bird() {
    return (
        // <svg className='bigBird' width="252" height="240" viewBox="0 0 252 240" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <ellipse cx="126.639" cy="149.637" rx="90.2961" ry="89.6373" fill="url(#paint0_linear_81_534)"/>
        //     <path fill-rule="evenodd" clip-rule="evenodd" d="M117.278 105.685H120.449C125.495 105.685 129.916 108.376 132.376 112.411C145.237 114.5 155.907 123.18 160.861 134.899C156.119 150.269 141.885 161.43 125.062 161.43C121.7 161.43 118.442 160.984 115.342 160.148C121.665 165.049 129.585 167.963 138.181 167.963C147.583 167.963 156.178 164.476 162.758 158.716C159.367 172.6 148.348 183.462 134.433 186.538V193.513H131.261C126.2 193.513 121.767 190.805 119.312 186.749C101.981 183.429 88.8789 168.091 88.8789 149.671C88.8789 138.295 93.8772 128.094 101.783 121.174L101.783 121.174C106.171 117.332 111.455 114.502 117.278 113.043V105.685Z" fill="#101527"/>
        //     <path d="M183.033 59.5708C184.012 59.8636 178.182 44.8126 137.302 16.2074C137.302 16.2074 122.119 3.61396 109.149 2.17028C96.1789 0.726604 59.8486 -7.52457 37.3366 23.4874C14.7918 54.4844 12.2523 157.123 12.2523 157.123L16.3078 159.262L32.5509 105.377C32.5662 105.345 102.57 36.4588 183.033 59.5708Z" fill="url(#paint1_linear_81_534)"/>
        //     <path d="M37.1568 26.5015C16.9403 44.0726 13.1795 144.695 12.6741 148.712L20.3445 150.496C23.5454 139.284 35.3924 105.314 35.7967 104.109C36.201 102.905 38.2643 69.8895 53.9321 46.1265C58.0125 27.3936 53.3301 12.4446 37.1568 26.5015Z" fill="url(#paint2_linear_81_534)"/>
        //     <path d="M210.281 115.685C210.281 115.685 218.179 74.7882 185.269 60.0002C137.103 38.357 65.1754 55.6009 35.2022 96.2602C35.2022 96.2602 23.9643 111.8 28.6203 128.635C33.2764 145.47 38.2141 154.49 38.2141 154.49C38.2141 154.49 38.1057 136.93 51.9093 124.692C65.7129 112.454 144.498 63.0782 210.281 115.685Z" fill="url(#paint3_linear_81_534)"/>
        //     <path d="M9.83814 171.986C2.0356 168.4 -1.33525 159.272 2.30912 151.597C5.9535 143.922 15.233 140.607 23.0356 144.193C30.8381 147.778 34.209 156.907 30.5646 164.582C26.9202 172.257 17.6407 175.572 9.83814 171.986Z" fill="url(#paint4_linear_81_534)"/>
        //     <path id='left_wing' d="M10.0655 201.463C10.5958 205.904 23.1402 201.245 30.4455 194.666C29.9181 197.063 24.391 201.871 26.2877 203.278C28.1844 204.685 33.2609 201.798 35.9374 198.473C38.4902 195.247 39.728 192.781 41.0065 189.01C39.9559 193.082 38.0301 199.445 40.541 199.173C43.0519 198.901 47.2921 193.49 48.8097 181.974C49.3364 183.816 49.4801 187.588 51.9678 187.743C54.4555 187.899 54.5358 180.481 52.743 176.822C48.4823 168.395 36.0801 167.492 30.4162 175.325C27.5076 179.356 24.9907 183.64 22.2754 187.776C17.3394 195.27 9.32267 198.733 10.0655 201.463Z" fill="url(#paint5_linear_81_534)"/>
        //     <path id='right_wing' d="M251.47 163.187C253.057 167.377 239.733 168.858 230.182 166.281C231.763 168.178 238.91 169.977 237.874 172.08C236.839 174.183 230.983 173.892 227.059 172.133C223.292 170.407 221.047 168.767 218.161 165.985C220.984 169.138 225.647 173.937 223.287 174.821C220.926 175.706 214.644 172.792 207.955 163.224C208.34 165.099 209.961 168.521 207.819 169.775C205.677 171.03 202.167 164.464 202.066 160.404C201.952 150.992 212.571 144.625 221.242 149.055C225.7 151.338 229.925 154.021 234.259 156.484C242.126 160.939 250.866 160.425 251.47 163.187Z" fill="url(#paint6_linear_81_534)"/>
        //     <defs>
        //         <linearGradient id="paint0_linear_81_534" x1="46.193" y1="73.7282" x2="211.663" y2="194.779" gradientUnits="userSpaceOnUse">
        //             <stop offset="0.0447088" stop-color="#FFFFE8"/>
        //             <stop offset="1" stop-color="#AFD3D3"/>
        //         </linearGradient>
        //         <linearGradient id="paint1_linear_81_534" x1="179.562" y1="48.2022" x2="-0.185795" y2="64.5217" gradientUnits="userSpaceOnUse">
        //             <stop offset="0.0447088" stop-color="#FDFC47"/>
        //             <stop offset="1" stop-color="#3EEC55"/>
        //         </linearGradient>
        //         <linearGradient id="paint2_linear_81_534" x1="33.9993" y1="20.9607" x2="33.9993" y2="150.496" gradientUnits="userSpaceOnUse">
        //             <stop stop-color="#9EEF38"/>
        //             <stop offset="1" stop-color="#00A811"/>
        //         </linearGradient>
        //         <linearGradient id="paint3_linear_81_534" x1="99.0476" y1="66.5948" x2="102.826" y2="108.154" gradientUnits="userSpaceOnUse">
        //             <stop stop-color="white"/>
        //             <stop offset="1" stop-color="#BACFD6"/>
        //         </linearGradient>
        //         <linearGradient id="paint4_linear_81_534" x1="20.4549" y1="153.499" x2="9.22247" y2="170.652" gradientUnits="userSpaceOnUse">
        //             <stop stop-color="white"/>
        //             <stop offset="1" stop-color="#DEE7EA"/>
        //         </linearGradient>
        //         <linearGradient id="paint5_linear_81_534" x1="24.6197" y1="181.989" x2="35.4568" y2="193.1" gradientUnits="userSpaceOnUse">
        //             <stop stop-color="white"/>
        //             <stop offset="1" stop-color="#DEE7EA"/>
        //         </linearGradient>
        //         <linearGradient id="paint6_linear_81_534" x1="229.49" y1="152.385" x2="224.891" y2="167.102" gradientUnits="userSpaceOnUse">
        //             <stop stop-color="white"/>
        //             <stop offset="1" stop-color="#DEE7EA"/>
        //         </linearGradient>
        //     </defs>
        // </svg>
//         :
        <svg className='bigBird' width="223" height="236" viewBox="0 0 223 226" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="112.944" cy="135.085" rx="80.2005" ry="80.9296" fill="url(#paint0_linear_81_534)"/>
<path d="M108.286 153.623C116.933 154.378 127.375 150.378 131.516 148.283C132.556 152.155 130.716 160.223 123.176 162.137C111.985 164.979 109.105 153.68 108.286 153.623Z" fill="#0F0F0F"/>
<ellipse cx="61.6423" cy="160.704" rx="19.8764" ry="11.5099" transform="rotate(-20.1732 61.6423 160.704)" fill="url(#paint1_radial_81_534)"/>
<ellipse cx="169.208" cy="138.417" rx="19.8764" ry="11.5099" transform="rotate(-0.753626 169.208 138.417)" fill="url(#paint2_radial_81_534)"/>
<path d="M163.033 53.7689C163.903 54.0332 158.724 40.4443 122.415 14.6179C122.415 14.6179 108.93 3.24784 97.41 1.9444C85.8899 0.640961 53.6215 -6.80867 33.6265 21.1907C13.6024 49.1765 11.3468 141.845 11.3468 141.845L14.9488 143.775L29.3759 95.1257C29.3895 95.0966 91.5665 32.9021 163.033 53.7689Z" fill="#FF8206"/>
<path d="M33.4674 23.9117C15.5112 39.7759 12.1709 130.624 11.7219 134.25L18.5347 135.861C21.3778 125.738 31.9002 95.0684 32.2593 93.9806C32.6184 92.8928 34.451 63.0849 48.3671 41.6303C51.9913 24.7172 47.8324 11.2203 33.4674 23.9117Z" fill="url(#paint3_linear_81_534)"/>
<path d="M187.235 104.431C187.235 104.431 194.25 67.5073 165.02 54.1558C122.239 34.6151 58.3529 50.1839 31.7309 86.8934C31.7309 86.8934 21.7494 100.924 25.8849 116.123C30.0203 131.323 34.406 139.466 34.406 139.466C34.406 139.466 34.3098 123.613 46.57 112.563C58.8303 101.514 128.807 56.9348 187.235 104.431Z" fill="url(#paint4_linear_81_534)"/>
<path d="M9.20306 155.263C2.27288 152.026 -0.721096 143.784 2.51582 136.855C5.75273 129.925 13.9948 126.932 20.925 130.17C27.8551 133.407 30.8491 141.649 27.6122 148.578C24.3753 155.507 16.1332 158.5 9.20306 155.263Z" fill="url(#paint5_linear_81_534)"/>
<ellipse rx="5.846" ry="7.01521" transform="matrix(-0.992483 0.122383 0.122383 0.992483 149.836 122.632)" fill="#0F0F0F"/>
<ellipse rx="5.846" ry="7.01521" transform="matrix(-0.992483 0.122383 0.122383 0.992483 77.3458 136.663)" fill="#0F0F0F"/>
<defs>
<linearGradient id="paint0_linear_81_534" x1="41.4926" y1="66.5504" x2="190.132" y2="173.522" gradientUnits="userSpaceOnUse">
<stop offset="0.0447088" stop-color="#FEFEFE"/>
<stop offset="1" stop-color="#C1D7D7"/>
</linearGradient>
<radialGradient id="paint1_radial_81_534" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(61.6423 160.704) rotate(90) scale(11.5099 19.8764)">
<stop stop-color="#F8B9B9"/>
<stop offset="1" stop-color="#F8B9B9" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint2_radial_81_534" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(169.208 138.417) rotate(90) scale(11.5099 19.8764)">
<stop stop-color="#F8B9B9"/>
<stop offset="1" stop-color="#F8B9B9" stop-opacity="0"/>
</radialGradient>
<linearGradient id="paint3_linear_81_534" x1="30.6628" y1="18.9092" x2="30.6628" y2="135.861" gradientUnits="userSpaceOnUse">
<stop stop-color="#FF8206"/>
<stop offset="1" stop-color="#A65300"/>
</linearGradient>
<linearGradient id="paint4_linear_81_534" x1="88.4381" y1="60.1098" x2="91.9052" y2="97.6212" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#BACFD6"/>
</linearGradient>
<linearGradient id="paint5_linear_81_534" x1="18.6328" y1="138.572" x2="8.42608" y2="153.905" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#DEE7EA"/>
</linearGradient>
</defs>
</svg>
        

    )
}