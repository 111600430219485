import { useState, useEffect } from 'react';
import './style.scss';

export default function Button({status}) {
    const [btn, setBtn] = useState(status);

    useEffect(() => {
        setBtn(status);
    }, [status]);

    return (
        <svg className="playBtn" width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_251_331)">
                <circle cx="37" cy="38" r="28" fill="white"/>
            </g>
            {(btn)? 
                <>
                    <rect x="30" y="29" width="6" height="19" fill="#101527"/>
                    <rect x="39" y="29" width="6" height="19" fill="#101527"/>
                </>
                :
                <path d="M31 48.9498L31 28.0502C31 27.2171 31.9591 26.7493 32.6156 27.2622L45.9913 37.712C46.5038 38.1123 46.5038 38.8877 45.9913 39.288L32.6156 49.7378C31.9591 50.2507 31 49.7829 31 48.9498Z" fill="#101527"/>
            }
            <defs>
                <filter id="filter0_d_251_331" x="0" y="0" width="84" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dx="5" dy="4"/>
                    <feGaussianBlur stdDeviation="7"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.339931 0 0 0 0 0.415708 0 0 0 0 0.458333 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_251_331"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_251_331" result="shape"/>
                </filter>
            </defs>
        </svg>
    );
}