import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    screen: 1,
    scene: 1
}

const performance = createSlice({
    name: 'performanceLogic',
    initialState,
    reducers: {
        setScene: (state, action) => {
            state.scene = action.payload
        },
        setScreen: (state, action) => {
            state.screen = action.payload
        }
    }
});

export const {setScene, setScreen} = performance.actions;
export default performance.reducer;