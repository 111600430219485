import Greetings from './../../greetings';
import Bird from './../../bird';
import Footer from './../../footer';
import { Start } from './../../buttons';

export default function Scene() {

    return (
        <div className="containerScene">
            <Greetings/>
            <Bird/>
            <Start/>
            <Footer/>
        </div>
    );
}