import { useState } from "react"
import './style.scss';
import { setCurrentBtn1, setCurrentBtn2, setCurrentBtn3, setMainColor } from "../../store/color";
import { useDispatch, useSelector } from 'react-redux';
import { setScene } from "../../store/performace";



export default function Color_var(props) {
    const c = useSelector(state => state.colors);
    const [active, setActive] = useState(props.active || false);

    const dispatch = useDispatch();

    let styles = {
        gradient1: {
            background: props.type1,
        },
        gradient2: {
            background: props.type2,
        }
    };

    function handlerClick() {
        setActive(!active);
        if (props.name === "Christmas" || props.name === "Веселе Різдво") {
            
            dispatch(setCurrentBtn1(true)).then(
                dispatch(setCurrentBtn2(false)).then(
                    dispatch(setCurrentBtn3(false)).then(
                        dispatch(setMainColor(1)).then(
                            dispatch(setScene(3))
                        )
                    )
                )
            )

        } else if (props.name === "EasyGrow" || props.name === "Мій друг Factum") {
            dispatch(setCurrentBtn1(false)).then(
                dispatch(setCurrentBtn2(true)).then(
                    dispatch(setCurrentBtn3(false)).then(
                        dispatch(setMainColor(2)).then(
                            dispatch(setScene(3))
                        )
                    )
                )
            )
        } else if (props.name === "Sky&Sand" || props.name === "Разом до перемоги!") {
            dispatch(setCurrentBtn1(false)).then(
                dispatch(setCurrentBtn2(false)).then(
                    dispatch(setCurrentBtn3(true)).then(
                        dispatch(setMainColor(3)).then(
                            dispatch(setScene(3))
                        )
                    )
                )
            )
        }
        
    }

    return (
        <div className={(active)? "active" : ""} onClick={handlerClick}>
            <div className={"btnChooseColor"}>
                <div style={styles.gradient1}></div>
                <div style={styles.gradient2}></div>
                <p>{props.name}</p>
            </div>
        </div>
    )
}