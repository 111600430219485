import { setScene } from '../../../store/performace';
import { useDispatch } from 'react-redux';
import './style.scss';

function Button() {
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(setScene(2));
    }

    return (
        <div 
            onClick={handleClick}
            className="startBtn">
            
            <p>
                Створіть своє особисте диво!
                {/* Let’s create your miracle now! */}
            
            </p>
        </div>
    );
}

export default Button;