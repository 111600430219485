import { useEffect, useState } from "react";
import Santa from '../../santa/index';
import { Play, Repeat, Share, Back, Copy } from "../../buttons";
import Greetings from "../../greetings";
import { useSelector } from 'react-redux';
import { SOUND_NAME_1, SOUND_NAME_2, SOUND_NAME_3 } from './../../../const'; 
import Footer from './../../footer';

export default function Scene() {
    const sounds = useSelector(state => state.sounds);
    const colors = useSelector(state => state.colors);
    const perform = useSelector(state => state.perform);
    const currSound = (sounds.mainMusic === 1) ? SOUND_NAME_1: (sounds.mainMusic === 2) ? SOUND_NAME_2 : (sounds.mainMusic === 3) ? SOUND_NAME_3 : null;
    const path = "./sounds/" + currSound + "_long.mp3";
    const [audio, setAudio] = useState( new Audio(path) );
    const [audioStatus, setAudioStatus] = useState(true);
    const [inputName, setInputName] = useState("");

    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        if (audioStatus) {
            audio.type = "audio/mp3";
            audio.volume = 0.6;
            audio.play();

        }
        if (colors.mainColor === 1) {
            document.body.getElementsByClassName('absoluteContainer')[0].style.setProperty('background',(width > 780) ? '#101527': 'linear-gradient(180deg, #FF5858 0%, #C81818 100%)');
            document.body.getElementsByTagName('FOOTER')[0].style.setProperty('filter', (perform.scene !== 4) ? (width > 780) ? 'brightness(100)' : 'none' : 'none');
            document.body.style.setProperty('background',  'linear-gradient(180deg, #A4EA4C 0%, #5FAA00 100%)' );
        }
        if (colors.mainColor === 2) {
            // document.body.getElementsByClassName('absoluteContainer')[0].style.setProperty('background', 'linear-gradient(180deg, #9EEF38 0%, #00A811 100%)');
            document.body.getElementsByClassName('absoluteContainer')[0].style.setProperty('background', '#FF8206');
            document.body.style.setProperty('background', '#101527');
        }
        if (colors.mainColor === 3) {
            document.body.getElementsByClassName('absoluteContainer')[0].style.setProperty('background', 'linear-gradient(180deg, #FFD600 0%, #FFA800 100%)');
            document.body.style.setProperty('background', 'linear-gradient(180deg, #009DFA 0%, #1434CB 100%)');
        }
        return () => {
            document.body.style.setProperty('background', '#E8F0F8');
            audio.pause();
        }
    })

    const playMusic = () => {
        if (audioStatus)  {
            setAudioStatus(!audioStatus);
            audio.pause();
        } 
        else {
            setAudioStatus(!audioStatus)
            audio.play();
        }
    }

    audio.onended=function() {
        audio.play();
    }

    const styles = {
        mask: {
            position: "absolute",
            top: "20px",
            right: "20px"
        }
    };

    return (
        <>
            <div className="containerScene" style={{transform: (width <= 780)? "none" : "translateX(200px)"}}>
                <Santa/>
                <Greetings/>
                {
                    (width > 780)?null:
                    <>
                        <div className="btnsRow" style={{display: "flex", gap: "0px 16px", width: '100%',margin:"20px 0px"}}>
                            <Repeat/>
                            <Share/>
                        </div>
                        <Footer/>
                    </>
                }
            </div>
            {(width > 780)?null:
            <span onClick={playMusic} style={styles.mask}>
                <Play status={audioStatus}/>
            </span>
            }
            <div style={{display: (width > 780)? 'block': (perform.scene === 5)? 'block' : 'none'}} className="absoluteContainer">
                {(perform.scene === 5)? <Back/>:null}
                <span onClick={playMusic} style={styles.mask}>
                    <Play status={audioStatus}/>
                </span>
                <div className="changeContentFunc">
                    {
                        (perform.scene === 4) ?
                        <>
                            <Repeat/>
                            <Share/>
                        </>
                        :
                        <div className="shareinfo">
                            <p style={{filter: (colors.mainColor === 1) ? (width > 780) ? "brightness(100)" : "none" : "none" }}>
                                {/* Input the name of the person who you would like to send a greeting or keep it empty */}
                                Введіть ім'я особи, які Ви хочете надіслати привітання, або залиште поле пустим
                            </p>
                            <input value={inputName} onChange={(e) => setInputName(e.target.value)} style={{color: (colors.mainColor === 1)?(width > 780)?'#FF4747':'#101527':(colors.mainColor === 2)?'#101527':'#1434CB'}} type="text"/>
                            <Copy nameLink={inputName}/>
                        </div>
                    }
                </div>
                <Footer/>
            </div>
        </>
    );
}