import { setScene, setScreen } from '../../../store/performace';
import { setMainColor } from '../../../store/color';
import { setMainMusic } from '../../../store/music';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import './style.scss';
import { useEffect } from 'react';

export default function Button() {
    const colors = useSelector(state => state.colors);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (colors.mainColor === 1) {
            document.body.getElementsByClassName('repeatBtn')[0].style.setProperty('background', '#FF4747');
        }
        if (colors.mainColor === 2) {
            document.body.getElementsByClassName('repeatBtn')[0].style.setProperty('background', '#101527');
        }
        if (colors.mainColor === 3) {
            document.body.getElementsByClassName('repeatBtn')[0].style.setProperty('background', '#1434CB');
        }
        return () => {
            
        }
    });

    const handleClick = () => {
        dispatch(setMainColor(null)).then(
            dispatch(setMainMusic(null)).then(
                dispatch(setScene(1)).then(
                    dispatch(setScreen(1))
                )
            )
        )
    }
    
    return (
        <div 
            onClick={handleClick}
            className="repeatBtn">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.875 1.62354V7.87354H13.625" stroke="white" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5003 20.3735C5.73193 20.3763 1.72111 16.7994 1.18026 12.0619C0.639411 7.32429 3.74084 2.93547 8.38712 1.86347C13.0334 0.791478 17.7441 3.37785 19.3336 7.87349" stroke="white" stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <p>
            Повторити
                {/* Repeat */}
            </p>
        </div>
    );
}