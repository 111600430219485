import { default as Screen1 } from './screen1';
import { default as Screen2 } from './screen2';
import { useSelector } from "react-redux";



function Performance() {
    const perform = useSelector(state => state.perform);
    return (perform.screen === 1) ? <Screen1/> : <Screen2/>;
}

export { Performance }