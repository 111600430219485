import Color_var from './../../color_var';
import Footer from './../../footer';
import {SOUND_NAME_1, SOUND_NAME_2, SOUND_NAME_3} from '../../../const';

import { Play } from '../../buttons';

import { setMainMusic } from "../../../store/music";
import { setScreen, setScene } from '../../../store/performace';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';


export default function Scene() {
    const [audio, setAudio] = useState( {
        audio1: new Audio("./sounds/" + SOUND_NAME_1 + "_short.mp3"),
        audio2: new Audio("./sounds/" + SOUND_NAME_2 + "_short.mp3"),
        audio3: new Audio("./sounds/" + SOUND_NAME_3 + "_short.mp3"),
    } );

    const [audioStatus, setAudioStatus] = useState({as1: false, as2: false, as3: false}); 
    const [active, setActive] = useState({a1: false, a2: false, a3: false});
    const perform = useSelector(state => state.perform);
    const c = useSelector(state => state.colors);

    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const dispatch = useDispatch();
    
    const handlerMusic1 = () => {
        setActive({a1: !active.a1, a2: false, a3: false})
        dispatch(setMainMusic(1))
    }

    const handlerMusic2 = () => {
        setActive({a1: false, a2: !active.a2, a3: false})
        dispatch(setMainMusic(2))
    }

    const handlerMusic3 = () => {
        setActive({a1: false, a2: false, a3: !active.a3})
        dispatch(setMainMusic(3))
    }

    useEffect(() => {
        audio.audio1.type = "audio/mp3";
        audio.audio2.type = "audio/mp3";
        audio.audio3.type = "audio/mp3";

        audio.audio1.volume = 0.4;
        audio.audio2.volume = 0.4;
        audio.audio3.volume = 0.4;
    });

    const playMusic1 = () => {
        
        audio.audio2.pause();
        audio.audio3.pause();
        setAudioStatus({
            as1: audioStatus.as1,
            as2: false,
            as3: false
        })
        if (audioStatus.as1)  {
            setAudioStatus({
                as1: !audioStatus.as1,
                as2: audioStatus.as2,
                as3: audioStatus.as3
            })
            audio.audio1.pause();
        } 
        else {
            setAudioStatus({
                as1: !audioStatus.as1,
                as2: false,
                as3: false
            })
            audio.audio1.play();
        }
    }
    const playMusic2 = () => {
        audio.audio1.pause();
        audio.audio3.pause();
        setAudioStatus({
            as1: false,
            as2: audioStatus.as2,
            as3: false
        })
        if (audioStatus.as2)  {
            setAudioStatus({
                as1: audioStatus.as1,
                as2: !audioStatus.as2,
                as3: audioStatus.as3
            })
            audio.audio2.pause();
        } 
        else {
            setAudioStatus({
                as1: false,
                as2: !audioStatus.as2,
                as3: false
            })
            audio.audio2.play();
        }
    }
    const playMusic3 = () => {
        audio.audio1.pause();
        audio.audio2.pause();
        setAudioStatus({
            as1: false,
            as2: false,
            as3: audioStatus.as3
        })
        if (audioStatus.as3)  {
            setAudioStatus({
                as1: audioStatus.as1,
                as2: audioStatus.as2,
                as3: !audioStatus.as3
            })
            audio.audio3.pause();
        } 
        else {
            setAudioStatus({
                as1: false,
                as2: false,
                as3: !audioStatus.as3
            })
            audio.audio3.play();
        }
    }

    audio.audio1.onended=function() {
        // setAudioStatus({
        //     as1: false,
        //     as2: audioStatus.as2,
        //     as3: audioStatus.as3
        // })
        audio.audio1.play();
    }
    audio.audio2.onended=function() {
        // setAudioStatus({
        //     as1: audioStatus.as1,
        //     as2: false,
        //     as3: audioStatus.as3
        // })
        audio.audio2.play();
    }
    audio.audio3.onended=function() {
        // setAudioStatus({
        //     as1: audioStatus.as1,
        //     as2: audioStatus.as2,
        //     as3: false
        // })
        audio.audio3.play();
    }

    const handleScreenTwo = () => {
            audio.audio1.pause();
            audio.audio2.pause();
            audio.audio3.pause();
            dispatch(setScreen(2)).then(
                dispatch(setScene(4))
            )
    }

    return (
        <div className="containerScene" style={{transform: (width < 375) ?'scale(0.9)':'scale(1)'}}>
            <p className="instruction">
            {
            (perform.scene === 2) ? 
                // "Choose the colors you would like to accompany you throughout the year" 
                "Оберіть кольори та розфарбуйте свій новий рік"
                : 
                // "Now choose the music that would set your mood in the new year"
                "Підберіть свій саундтрек до нового року"
            }
            </p>

            <div className="variablesContainer">
                {(perform.scene === 2) ?
                    <>
                        <Color_var 
                            name="Веселе Різдво"
                            // name="Christmas"
                            active={c.btn1} 
                            type1={"linear-gradient(180deg, #FF5858 0%, #C81818 100%)"} 
                            type2={"linear-gradient(180deg, #A4EA4C 0%, #5FAA00 100%)"}/>

                        {/* <Color_var 
                            name="EasyGrow" 
                            active={c.btn2} 
                            type1={"linear-gradient(125.99deg, #FDFC47 10.02%, #3EEC55 87.87%)"} 
                            type2={"#101527"}/> */}
                        <Color_var 
                            name="Мій друг Factum" 
                            active={c.btn2} 
                            type1={"#FF8206"} 
                            type2={"#0F0F0F"}/>

                        <Color_var 
                            name="Разом до перемоги!"
                            // name="Sky&Sand" 
                            active={c.btn3} 
                            type1={"linear-gradient(180deg, #009DFA 0%, #1434CB 100%)"} 
                            type2={"linear-gradient(180deg, #FFD600 0%, #FFA800 100%)"}/>
                    </>
                    :
                    <>

        <div className="flex_btns" onClick={handlerMusic1}>
            <div className={(active.a1)?"active": ""}>
                <div className="soundContainer">
                    <svg width="39" height="43" viewBox="0 0 39 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="9" cy="35" rx="7" ry="6" stroke="#101527" stroke-width="3"/>
                        <ellipse cx="30" cy="29" rx="7" ry="6" stroke="#101527" stroke-width="3"/>
                        <path d="M16 35.5V19M37 29.5V12.5M16 19V10L37 3V12.5M16 19L37 12.5" stroke="#101527" stroke-width="3"/>
                    </svg>
                    <p>
                        {SOUND_NAME_1}
                    </p>
                    <span onClick={playMusic1}>
                        <Play status={audioStatus.as1}/>
                    </span>                
                </div>
                <div className="useSoundBtn" onClick={handleScreenTwo}>
                    <p>
                        {/* Use */}
                        Далі
                    </p>
                </div>
            </div>
        </div>

        <div className="flex_btns" onClick={handlerMusic2}>
            <div className={(active.a2)?"active": ""}>
                <div className="soundContainer">
                    <svg width="39" height="43" viewBox="0 0 39 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="9" cy="35" rx="7" ry="6" stroke="#101527" stroke-width="3"/>
                        <ellipse cx="30" cy="29" rx="7" ry="6" stroke="#101527" stroke-width="3"/>
                        <path d="M16 35.5V19M37 29.5V12.5M16 19V10L37 3V12.5M16 19L37 12.5" stroke="#101527" stroke-width="3"/>
                    </svg>
                    <p>
                        {SOUND_NAME_2}
                    </p>
                    <span onClick={playMusic2}>
                        <Play status={audioStatus.as2}/>
                    </span>
                </div>
                <div className="useSoundBtn" onClick={handleScreenTwo}>
                    <p>
                        {/* Use */}
                        Далі
                    </p>
                </div>
            </div>
        </div>

        <div className="flex_btns" onClick={handlerMusic3}>
            <div className={(active.a3)?"active": ""}>
                <div className="soundContainer">
                    <svg width="39" height="43" viewBox="0 0 39 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="9" cy="35" rx="7" ry="6" stroke="#101527" stroke-width="3"/>
                        <ellipse cx="30" cy="29" rx="7" ry="6" stroke="#101527" stroke-width="3"/>
                        <path d="M16 35.5V19M37 29.5V12.5M16 19V10L37 3V12.5M16 19L37 12.5" stroke="#101527" stroke-width="3"/>
                    </svg>
                    <p>
                        {SOUND_NAME_3}
                    </p>
                    <span onClick={playMusic3}>
                        <Play status={audioStatus.as3}/>
                    </span>
                </div>
                <div className="useSoundBtn" onClick={handleScreenTwo}>
                    <p>
                        {/* Use */}
                        Далі
                    </p>
                </div>
            </div>
        </div>

                    </>}
            </div>

            <Footer/>    
        </div>
    );
}