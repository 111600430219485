import './style.scss';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { HOST2 } from '../../const';


export default function Greetings() {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl).get("name");
    const perform = useSelector(state => state.perform);
    const name = (params === null || params === undefined || params.length <= 0) ?  "друже" : params;

    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return(
        <div className="greetings" style={{marginTop: (perform.screen !== 1) ? (width < 780) ? "24px":"45px": "initial"}}>
            
            <h1 style={{fontSize: ((perform.screen === 2) ? (width < 780)? "25px":"40px" : "40px")}}>
                {/* Dear {name}, */}
                Вітаємо, {name}!
            </h1>
            
            {(perform.screen === 1)? 
                <p>
                    Нехай новий рік принесе здійснення Вашої головної мрії!
                    {/* May all your very own wishes come true in the upcoming year! */}
                </p>
                :
                <p style={{fontSize: ((perform.screen === 2) ? (width < 780)? "16px":"18px" : "18px")}}>
                    {/* Merry Christmas and a Happy New Year!
                    <br/> 
                    May all your wishes come true your own way! */}
                    З Різдвом та Новим роком!
                    <br />
                    Нехай у новому році здійсниться Ваша особиста мрія та мрія кожного українця!
                </p>
            }
            
        </div>
    );
}