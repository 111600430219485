import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mainMusic: null
}

const musicLogic = createSlice({
    name: 'musicLogic',
    initialState,
    reducers: {
        setMainMusic: (state, action) => {
            state.mainMusic = action.payload
        }
    }
});

export const {setMainMusic} = musicLogic.actions;
export default musicLogic.reducer;