import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    btn1: false,
    btn2: false,
    btn3: false,
    mainColor: null
}

const colorLogic = createSlice({
    name: 'colorChoose',
    initialState,
    reducers: {
        setCurrentBtn1: (state, action) => {
            state.btn1 = action.payload
        },
        setCurrentBtn2: (state, action) => {
            state.btn2 = action.payload
        },
        setCurrentBtn3: (state, action) => {
            state.btn3 = action.payload
        },
        setMainColor: (state, action) => {
            state.mainColor = action.payload
        }
    }
});

export const {setCurrentBtn1, setCurrentBtn2, setCurrentBtn3, setMainColor} = colorLogic.actions;
export default colorLogic.reducer;