import { configureStore } from "@reduxjs/toolkit";
import performace from "./performace";
import color from "./color";
import music from "./music";

const store = configureStore({
    reducer: {
        perform: performace,
        colors: color,
        sounds: music
    }
});


export default store;