import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';

export default function Button({nameLink}) {
    const [copySuccess, setCopySuccess] = useState(false);
    const [name, setName] = useState(window.location.protocol + '//' + window.location.host + "?name=" + nameLink);
    const colors = useSelector(state => state.colors);

    useEffect(() => {
        setName(window.location.protocol + '//' + window.location.host + "?name=" + nameLink);
        setCopySuccess(false);
    }, [nameLink]);

    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        if (colors.mainColor === 1) {
            document.body.getElementsByClassName('copyBtn')[0].style.setProperty('background', (width > 780) ? '#FF4747' : '#7FE342');
        }
        if (colors.mainColor === 2) {
            document.body.getElementsByClassName('copyBtn')[0].style.setProperty('background', '#101527');
        }
        if (colors.mainColor === 3) {
            document.body.getElementsByClassName('copyBtn')[0].style.setProperty('background', '#1434CB');
        }
    });

    const handleClick = async () => {
        if ("clipboard" in navigator) {
            try {
                await navigator.clipboard.writeText(name);
                setCopySuccess(true);
            } catch (err) {
                setCopySuccess(false);
            }
        } else {
            try {
                document.execCommand("copy", true, name);
                setCopySuccess(true);
            } catch (err) {
                setCopySuccess(false);
            }
        }
    }

    return (
        <>
            <div 
                onClick={handleClick}
                className="copyBtn">
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.16667 3.33341H3.83333C3.09695 3.33341 2.5 3.93037 2.5 4.66675V18.0001C2.5 18.7365 3.09695 19.3334 3.83333 19.3334H14.5C15.2364 19.3334 15.8333 18.7365 15.8333 18.0001V16.6667M17.1667 16.6667H6.5C5.76362 16.6667 5.16667 16.0698 5.16667 15.3334V2.00008C5.16667 1.2637 5.76362 0.666748 6.5 0.666748H14.5L18.5 3.33341V15.3334C18.5 16.0698 17.903 16.6667 17.1667 16.6667Z" stroke={(colors.mainColor === 1) ? (width <= 780) ? "#101527" : "white" : "white"} stroke-width="1.25352" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p style={{color: (colors.mainColor === 1)? (width <= 780) ? '#101527' : '#FFFFFF' : '#FFFFFF'}}>
                    Копіювати
                    {/* Copy link */}
                </p>
            </div>
            {copySuccess ? 
                <div className="successCopy">
                    <p style={{filter: (colors.mainColor === 1) ? "brightness(100)" : "none" }}>
                        Посилання скопійовано
                        {/* Link is copied */}
                    </p>
                    <p style={{filter: (colors.mainColor === 1) ? "brightness(100)" : "none" }}>
                        {name}
                    </p>
                </div>
            : null
            }
        </>
    )
}